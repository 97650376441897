import React from 'react'
import "./styles.css"


const Header = (props) => {
	return (
		<div className="header-wrapper">
			<div className="logo"></div>
		</div>
	)
}

export default Header;