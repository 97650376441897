import React, { useState, useEffect } from "react"
import ReactGA from 'react-ga';
import axios from "axios"
import uuid from "react-uuid"
import './styles.css'

const Menu = (props) => {
    const [featured, setFeatured] = useState([])
    const [food, setFood] = useState([])
    
    useEffect(() => {
        ReactGA.initialize("UA-121101365-1")
        ReactGA.pageview(window.location.pathname + window.location.search)
      }, [])

    useEffect(() => {
        const debug = false;
        axios.get((debug ? `http://localhost:5000/` : `/api/`) + `featured`)
        .then(res => {
            setFeatured(res.data)
        })
        axios.get((debug ? `http://localhost:5000/` : `/api/`) + `groups`)
        .then(res => {
            setFood(res.data)
        })
    }, [])
    if (featured && food) {
        return (<div className="menu-wrapper">
            <QuickSelectSection featured={featured} food={food} />
            <Notice />
            { food.map(obj => {
                return <MenuSection key={uuid()} section={obj["name"]} items={obj["items"]} />
            })}
            <BackToTop />
        </div>)
    }
    return (
        <div className="menu-wrapper">
            <QuickSelectSection />

            <BackToTop />
        </div>
    )
}

const Notice = (props) => {
    return (<div className="notice">
        <p>Images are shown for illustration purposes only, actual product may vary.</p>
        <p>Prices are subject to change.</p>
    </div>)
}

const BackToTop = (props) => {
    const [bgColor, setBgColor] = useState("rgba(216, 216, 216, 0.8)")

    const backToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }

    return (
        <div className="back-to-top" 
            style={{backgroundColor: bgColor }} 
            onClick={backToTop}
            onMouseOver={() => setBgColor("rgba(216, 216, 216, 1)")} 
            onMouseOut={() => setBgColor("rgba(216, 216, 216, 0.8)")}>
            Back To Top
        </div>
    )
}

const QuickSelectSection = (props) => {
    if (props.featured && props.food) {
        return (<div className="quick-select-section">
            {props.food.map(obj => {
                return <QuickSelectItem {...props} key={uuid()} section={obj["name"]} bgimg={props.featured.find(res => {
                    return res["featured_group_id"] === obj["id"]
                })} />
            })}
        </div>)
    }
    return (
        <div className="quick-select-section">
        </div>
    )
}

const QuickSelectItem = (props) => {
    const [bgColor, setBgColor] = useState("rgba(216, 216, 216, 0.4)")

    const getFilteredId = (rawId) => {
        if (typeof rawId === 'undefined') {
            return '0'
        }
        rawId = rawId.toString()
        if (rawId.startsWith("9") && rawId.length > 2) {
            
            return rawId.substring(2, rawId.length) + String.fromCharCode(65 + parseInt(rawId.charAt(1)))
        }
        return rawId
    }

    const handleSelect = () => {
        // this should really be using refs
        window.scrollTo({ top: document.getElementById(props.section).getBoundingClientRect().y, left: 0, behavior: 'auto'})
    }

    return (
        <div className="quick-select-item" onClick={() => handleSelect()}>
            <div className="quick-select-header-wrapper" 
            >{ props.bgimg ? <img style={{backgroundColor: bgColor, transition: "0.125s background-color" }} 
            onMouseOver={() => setBgColor("rgba(216, 216, 216, 1)")} 
            onMouseOut={() => setBgColor("rgba(216, 216, 216, 0.4)")} className="quick-select-img" src={`./images/${getFilteredId(props.bgimg["item_id"])}.jpg`} alt=""></img> : null }
                <div className="quick-select-featured-header">
                    <div className="quick-select-featured-header-wrapper">
                        <h5 className="quick-select-header" >{props.section}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MenuSection = (props) => {

    // takes an id from db and formats it - 9034 -> 34A
    const getId = (rawId) => {
        rawId = rawId.toString()
        if (rawId.startsWith("9") && rawId.length > 2) {
            return rawId.substring(2, rawId.length) + String.fromCharCode(65 + parseInt(rawId.charAt(1)))
        }
        return rawId
    }
    // takes a price from db and formats it into a json object
    const getPrice = (rawPrice) => {
        let prices = rawPrice.trim().split(" ")
        if (prices.length > 0) {
            let finalPrices = {}
            for (let i = 0; i < prices.length; i++) {
                let key = ""
                let val = prices[i]
                if (prices[i].includes("-")) {
                    let kv = prices[i].split("-")
                    key = kv[0]
                    val = kv[1]
                }
                finalPrices[key] = val
            }
            return finalPrices
        }
        return {"" : rawPrice}
    }

    return (
        <div id={props.section} className="menu-section">
            <div className="menu-section-header">{props.section}</div>
            <div className="menu-item-group">
                { props.items ? props.items.map(obj => {
                    return <MenuItem key={uuid()} num={getId(obj["foodid"])} name={obj["foodnameeng"]} prices={getPrice(obj["price"])} />
                })  : null }
            </div>
        </div>
    )
}

const MenuItem = (props) => {
    return (
        <div className="menu-item">
            <div className="menu-item-num">{props.num}</div>
            <img className="menu-item-img" src={`./images/${props.num}.jpg`} alt="" />
            <div className="menu-item-wrapper">
                <div className="menu-item-name-wrapper">
                    <h5 className="menu-item-name">{props.name}</h5>
                </div>
                <div className="menu-item-price-wrapper">
                    <div className="menu-item-price">{Object.keys(props.prices).map((k, i)=> {
                        return (<div className="menu-item-price-div" key={uuid()}>{props.prices[k]}<div className="menu-item-price-caption">{k.charAt(0).toUpperCase()}</div><span className="menu-item-price-sep">{(i < Object.keys(props.prices).length - 1 ? "/" : "")}</span></div>)})
                    }</div>
                </div>
            </div>
            {/* <ul className="menu-item-price-list" onClick={togglePrice}>{Object.keys(props.prices).map(k => {
                return (<li className="menu-item-price-elem">{k.charAt(0).toUpperCase() + k.slice(1)} <span className="menu-item-price-num">{props.prices[k]}</span></li>)
            })}</ul> */}
        </div>
    )
}

export default Menu