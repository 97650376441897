import React, { useEffect } from "react"
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import "./styles.css"

const NotFound = (props) => {
    useEffect(() => {
        ReactGA.initialize("UA-121101365-1")
        ReactGA.pageview(window.location.pathname + window.location.search)
      }, [])
    return (
        <div className="notfound-wrapper">
            <div className="notfound">
                <div className="block">
                    <h1 className="notfoundheader">404!</h1>
                    <p>That page could not be found...</p>
                    <p>Click <Link to="/" className="returntomain">here</Link> to return to the main site.</p>
                </div>
            </div>
        </div>
    )
}

export default NotFound;
