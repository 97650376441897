import React, { useEffect } from "react"
import ReactGA from 'react-ga'
import "./styles.css"

const AboutUs = (props) => {
    useEffect(() => {
        ReactGA.initialize("UA-121101365-1")
        ReactGA.pageview(window.location.pathname + window.location.search)
      }, [])
    
    return (
        <div className="aboutus-wrapper">
            <div className="aboutus">
            <img className="aboutusimg" src="images/outsiderestaurant.jpg" alt=""></img>
            <div className="aboutus-text-wrapper">
                <p className="aboutustext"><b>Dynasty House Seafood Restaurant</b> is located on the corner of Lawrence Avenue West and Black Creek Drive.
            Established in 2007, we provide Authentic Chinese Cuisine specializing in live seafood. We serve a variety of foods, from All Day Dim Sum and BBQ to fried rice, noodles, and vegetarian options.
            Dynasty House is family friendly and ideal for business meetings, casual meet ups, baby shower or wedding dinners.</p>
            </div>
            </div>
        </div>
    )
}

export default AboutUs;