import React from 'react';
import Footer from './js/components/Footer';
import Main from './js/components/Main';
import Header from './js/components/Header';
import Nav from './js/components/Nav';
import { BrowserRouter } from "react-router-dom";
import './App.css';

const App = () => {
  return (
    <BrowserRouter>
        <div className="App">
            <Header/>
            <Nav/>
            <Main/>
            <Footer/>
        </div>
    </BrowserRouter>
  );
}

export default App;

