// React Imports
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Menu from '../Menu';
import AboutUs from '../AboutUs';
import Contact from '../Contact';
import NotFound from '../NotFound';

export default class Main extends React.Component {
	render() {
		return (
			<Switch>
    <Route exact path='/' component={Menu} />
    <Route path='/aboutus' component={AboutUs} />
    <Route path='/contact' component={Contact} />
				<Route path='/404' component={NotFound} />
				<Redirect to="/404" />
   </Switch>         
		);
	}
}
