import React from 'react';
import './styles.css';

export default class Footer extends React.Component {
	render() {
		return (
			<div className={"footer-wrapper"}>
				<div className={"footer"}>
                    <p>&copy; Dynasty House Seafood Restaurant. 2021.</p>
				</div>
			</div>
		);
	}
}