import React from 'react'
import { Link } from "react-router-dom"
import './styles.css'

const Nav = (props) => {  
  const NavElem = (props) => {
    return (
        <Link className={'nav-bar-elem nav-bar-elem-highlight'} to={props.href}>
            <div className={'nav-bar-elem-item'} >
                { props.title }
            </div>
        </Link>
    )    
  }


  return (
    <div className="nav-bar-container-wrapper">
        <div className="nav-bar-container">
            <NavElem title={'MENU'} href={"/"}  icon={""} /> 
            <NavElem title={'ABOUT US'} href={"/aboutus"} icon={""}/>
            <NavElem title={'CONTACT'} href={"/contact"} icon={""} /> 
        </div>
    </div>
  )
}


export default Nav