import React, { useEffect } from "react"
import ReactGA from 'react-ga';
import "./styles.css"

const Contact = (props) => {
    useEffect(() => {
        ReactGA.initialize("UA-121101365-1")
        ReactGA.pageview(window.location.pathname + window.location.search)
      }, [])
    return (
        <div className="contact-wrapper">
            <div className="contact">
                <div className="block">
                    <h1 className="contactheader">Contact</h1>
                    <h4>Tel: (416) 246-1771</h4>
                    <h4>1635 Lawrence Avenue West Unit 12</h4>
                    <h4>North York, Ontario M6L 3C9</h4>
                </div>
                <div className="block">
                    <h1 className="contactheader">Business Hours</h1>
                    <p className="highlight-temporary">Please note that we are operating on reduced hours.</p>
                    <h4>Wednesday : <span className="highlight-closed">Closed</span></h4>
                    <h4>Monday, Tuesday, and Thursday : <span className="highlight-open">Open</span> 10 AM to 10 PM</h4>
                    <h4>Friday, Saturday, Sunday, and Holiday : <span className="highlight-open">Open</span> 10 AM to 10 PM</h4>
                </div>
                { /*<div className="block">
                    <h1 className="contactheader">Other Services</h1>
                    <h4>Dim Sum Cart Service on Weekend/Holiday rush hours.</h4>
                    <h4>High chairs or booster seats are available upon request.</h4>
                    </div>*/}
                <div className="block">
                    <h1 className="contactheader">Parking</h1>
                    <h4>Free parking spaces are available in the plaza.</h4>
                </div>
            </div>
        </div>
    )
}

export default Contact;
